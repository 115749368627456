.matt__content {
    align-self: center;
}

.matt__content p,
.matt__content h2 {
    text-align: left;
}

.matt__content h2 {
    padding: 0px 10px;
    border-left: 3px solid var(--blue);
}

.matt__content button {
    margin-top: 15px;
}

.matt img {
    border-radius: 5%;
}

.resume {
    display: flex;
    flex-direction: column;
}

.resume div {
    max-width: 45px;
    cursor: pointer;
    margin: 5px;
}

.resume__content {
    width: 100%;
    height: 60vh;
}

.person {
    font-size: 24px;
    padding: 5px 0px;
}

.people__content h2 {
    margin-top: 0px;
}

.people__content h3 {
    margin: 0;
}

.people__wrapper {
    padding: 20px 40px;
}

.people__content img {
    border-radius: 22px;
}

.people__content__title {
    display: inline-block;
    padding: 0px 10px;
    border-left: 3px solid var(--blue);
}