.primary-nav {
    background-color: var(--white);
}

.primary-nav__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
}

.primary-nav__items {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    column-gap: 40px;
}

.primary-nav__items li a {
    text-decoration: none;
    color: var(--black);
}

.primary-nav__items li a:hover {
    color: var(--blue);
}

.primary-nav img {
    max-width: 400px;
}

.sticky-header {
    top: 0;
    position: sticky;
    z-index: 3;
}

.nav__active {
    color: var(--blue);
}