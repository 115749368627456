label, select, input, textarea {
    font-size: 22px;
    font-family: 'Kumbh Sans', sans-serif;
    width: 100%;
}

input, select, textarea {
    margin: 5px 0px 30px 0px;
}

input::placeholder {
    opacity: 70%;
}

textarea {
    min-height: 300px;
}

.add-project {
    width: 650px;
    margin: auto;
}

.add-form {
    width: 450px;
    margin: auto;
}

.form__content {
    text-align: left;
}

.form__center {
    width: 300px;
    margin: auto;
}

.form__header {
    margin-top: 0;
}