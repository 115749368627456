.contact h3 {
    max-width: 45%;
    text-align: left;
    margin: 40px auto;
}

.contact div div h3 {
    text-align: center;
}

.contact {
    height: 100%;
}