.involved div div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 20px 30px;
}

.involved h1 {
    align-self: flex-start;
    padding: 0px 10px;
    margin: 10px 0px;
    border-left: 3px solid var(--blue);
}

.involved p {
    align-self: flex-start;
}

.faq h1 {
    display: inline-block;
    padding: 0px 10px;
    border-left: 3px solid var(--blue);
}

.faq {
    background-color: var(--darkblue);
    color: var(--white);
}

.faq summary {
    font-size: 30px;
    font-family: 'Manrope', sans-serif;
    text-align: left;
    margin: 40px auto 0px auto;
    padding: 10px 10px;
    border-bottom: 3px solid var(--blue);
    list-style: none;
}

.faq p {
    text-align: left;
    margin: 5px 0px 20px 0px;
    padding: 10px 10px;
    color: var(--lightblue);
}

details {
    cursor: pointer;
    position: relative;
    max-width: 70%;
    margin: auto;
}

details summary {
    list-style: none;
}

details[open] summary {
    color: var(--lightblue);
}

summary h3 {
    margin: 0;
    max-width: 92%;
}

summary::-webkit-details-marker {
    display: none;
}

.chevron--right svg {
    max-width: 25px;
}

.chevron--down svg {
    max-width: 28px;
}

.chevron--down,
.chevron--right {
    position: absolute;
    right: 1%;
}

.chevron--down svg,
.chevron--right svg {
    background: var(--darkblue);
}

.chevron--down {
    top: 18%;
}

.chevron--right {
    top: 14%;
}