button, .button {
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    border-radius: 16px;
}

.button--white {
    color: white;
    border: 2px solid var(--white);
}

.button--white:hover {
    background-color: var(--white);
    color: var(--black);
}

.button--blue {
    color: var(--blue);
    border: 2px solid var(--blue);
}

.button--blue:hover {
    background-color: var(--blue);
    color: var(--black);
}

.button--lightblue {
    color: var(--lightblue);
    border: 2px solid var(--lightblue);
}

.button--lightblue:hover {
    background-color: var(--lightblue);
    color: var(--lightblack);
}

.button__small {
    display: inline-block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    cursor: pointer;
    border-radius: 16px;
    font-size: 20px;
    font-family: 'Kumbh Sans', sans-serif;
}

.button__small > * {
    vertical-align: top;
}

.button--red {
    color: rgb(213, 9, 9);
    border: 2px solid rgb(213, 9, 9);
}

.button--red:hover {
    background-color: rgb(213, 9, 9);
    color: var(--black);
}

.button--green {
    color: yellowgreen;
    border: 2px solid yellowgreen;
}

.button--green:hover {
    background-color: yellowgreen;
    color: var(--black);
}

.button--green__important {
    background-color: yellowgreen;
    color: var(--black);
    border: 2px solid yellowgreen;
}

.button--green__important:hover {
    border: 2px solid black;
}