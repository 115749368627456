.add--button__content {
    margin-bottom: 20px;
    cursor: pointer;
    display: inline-block;
}

.add--button__content h3 {
    display: inline-block;
    padding: 0px 10px;
    color: var(--black);
    margin: 0;
}

.add--button__content svg {
    max-width: 40px;
    vertical-align: bottom;
}

.add--button__content:hover h3 {
    color: var(--blue);
}

.plus-back {
    fill: var(--white);
}

.add--button__content:hover .plus-back {
    fill: var(--lightblue);
}

.plus-circle {
    fill: var(--black);
}

.add--button__content:hover .plus-circle {
    fill: var(--white);
}

.sub--nav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 20px;
}

.sub--nav a:first-child {
    grid-column: 2;
}

.sub--nav a {
    text-decoration: none;
    color: var(--lightblue);
}

.sub--nav a div {
    padding: 13px 20px;
    box-shadow: inset 0 0 7px var(--lightblue);
}

.sub--nav a:first-child div {
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
}

.sub--nav a:last-child div {
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
}

.sub--nav__active {
    background-color: var(--darkblue);
}

.project__content__admin {
    padding: 15px;
    background-color: var(--darkblue);
    color: var(--white);
    border-bottom-left-radius: 22px;    
    border-bottom-right-radius: 22px; 
}

.project__content__admin h3 {
    margin: 0;
    padding-bottom: 10px;
}

.project__content__admin p {
    padding-bottom: 20px;
}

.buttons svg {
    max-width: 25px;
    margin-left: 8px;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.button--red:hover svg path {
    stroke: var(--black);
}

.button--green:hover svg path {
    stroke: var(--black);
}

.people__admin {
    margin-bottom: 60px;
}

.people__admin p {
    padding: 10px 0px;
}

.popup {
    width: 70%;
    height: 100%;
    background-color: var(--white);
    border: 3px solid var(--blue);
    border-radius: 22px;
    padding: 30px;
    margin: auto;
}

.popup svg {
    max-width: 25px;
    margin-left: 8px;
}

[role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}

.logout--container {
    position: relative;
    margin: 0px 40px;
}

.logout--container button {
    position: absolute;
    right: 0;
    top: 0;
}

.resume__button {
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 0;
}

.pos-relative {
    position: relative;
}