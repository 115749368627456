@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans&family=Manrope&display=swap');

h1 {
    font-size: 50px;
    font-family: 'Manrope', sans-serif;
    font-weight: normal;
}

h2 {
    font-size: 40px;
    font-family: 'Manrope', sans-serif;
    font-weight: normal;
}

h3 {
    font-size: 30px;
    font-family: 'Manrope', sans-serif;
    font-weight: normal;
}

p {
    font-size: 20px;
    font-family: 'Kumbh Sans', sans-serif;
}

a {
    font-size: 20px;
    font-family: 'Kumbh Sans', sans-serif;
    color: var(--blue);
}

a:hover {
    color: var(--darkblue);
}